import React, {Component} from 'react';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors/index';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import {mapLabels, formatDateWithoutTime, capitalizeFirstLetter} from '../../utils';
import TablePaginationActions from '../../components/TablePaginationActions';
import TableLink from '../../components/TableLink';

class RequestListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openExportMenu: null,
    loading: false,
  };

  getColoredStatus = data => {
    const feedbackStatus = [];
    let cnt = 0;
    ['yes', 'no', 'wait', 'viewed'].forEach(status => {
      if (data['feedback' + capitalizeFirstLetter(status)] === true) {
        feedbackStatus.push(
          <div
            key={(status + data.id)}
            className={this.props.classes.status}
            style={{backgroundColor: (this.props.requestFeedbackColors[status] || colors.white), left: (cnt++ * 6)}}
          />
        );
      }
    });
    return feedbackStatus;
  };

  render() {
    const {
      classes,
      query,
      deals,
      selectedItems,
      history,
      totalCount,
      offerTypeLabels,
      handleRequestSort,
      handleChangePage,
      perPage,
      dealListSettings,
      dealSourceLabels,
      activitiesLabels,
      activitiesDetailsLabels,
      requestActions,
      requestResults,
    } = this.props;

    const {order} = query;
    const {anchorEl, popoverContent} = this.state;
    const columnsSettings = dealListSettings.columns;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>
                  <TableSortLabel
                    active={order && order.column === 'date'}
                    direction={order ? order.direction.toLowerCase() : 'asc'}
                    onClick={() => handleRequestSort('date')}
                    classes={{root: classes.sortableCell}}
                  >
                    Дата заявки
                  </TableSortLabel>
                </TableCell>
                {columnsSettings.source ?
                  <TableCell>Источник</TableCell>
                  : null}
                {columnsSettings.activity ?
                  <TableCell>Сфера&nbsp;деятельности&nbsp; <br/> Детали&nbsp;деятельности</TableCell>
                  : null}
                {columnsSettings.area ?
                  <TableCell>Площадь</TableCell>
                  : null}
                {columnsSettings.price ?
                  <TableCell>Бюджет</TableCell>
                  : null}
                {columnsSettings.contact ?
                  <TableCell>Контакт&nbsp;/ Телефон</TableCell>
                  : null}
                {columnsSettings.requirements ?
                  <TableCell>Дополнительные пожелания</TableCell>
                  : null}
                {columnsSettings.offers ?
                  <TableCell>Предложенные листинги</TableCell>
                  : null}
                {columnsSettings.action ?
                  <TableCell>Действия</TableCell>
                  : null}
                {columnsSettings.result ?
                  <TableCell>Результат</TableCell>
                  : null}
                {columnsSettings.assignee ?
                  <TableCell>Ответственный&nbsp;/&nbsp;
                    <TableSortLabel
                      active={order && order.column === 'updatedAt'}
                      direction={order && order.direction ? order.direction.toLowerCase() : 'asc'}
                      onClick={() => handleRequestSort('updatedAt')}
                      classes={{root: classes.sortableCell}}
                    >
                      Изменена
                    </TableSortLabel>
                  </TableCell>
                  : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {deals.map(deal => {
                const feedbackStatus = this.getColoredStatus(deal);
                const selected = selectedItems && selectedItems.indexOf(deal.id) >= 0;
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={deal.id}
                    href={`/requests/${deal.id}`}
                    hover
                    selected={selected}
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/requests/${deal.id}`);
                    }}
                  >
                    <TableCell>
                      {feedbackStatus}
                      <div style={{display: 'flex'}}>
                        <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre-wrap'}}>
                          {formatDateWithoutTime(deal.date)}
                          <Typography variant="caption">
                            № {deal.no} - {offerTypeLabels[deal.type] || deal.type}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    {columnsSettings.source ?
                      <TableCell>
                        {deal.source ? dealSourceLabels[deal.source] || deal.source : null}
                      </TableCell>
                      : null}
                    {columnsSettings.activity ?
                      <TableCell>
                        {activitiesLabels[deal.activity] || deal.activity}
                        {deal.activityDetails ? (
                          <Typography variant="caption">
                            {activitiesDetailsLabels[deal.activityDetails] || deal.activityDetails}
                          </Typography>
                        ) : null}
                      </TableCell>
                      : null}
                    {columnsSettings.area ?
                      <TableCell>
                        {deal.areaFrom ? (' от ' + deal.areaFrom + ' м²\n') : null}
                        {deal.areaTo ? (' до ' + deal.areaTo + ' м²') : null}
                      </TableCell>
                      : null}
                    {columnsSettings.price ?
                      <TableCell>
                        {deal.priceFrom ? (' от ' + deal.priceFrom + ' ₽\n') : null}
                        {deal.priceTo ? (' до ' + deal.priceTo + ' ₽') : null}
                      </TableCell>
                      : null}
                    {columnsSettings.contact ?
                      <TableCell>
                        {deal.contactName ? deal.contactName + (deal.contactPhone ? '\n' + deal.contactPhone : '') : null}
                      </TableCell>
                      : null}
                    {columnsSettings.requirements ?
                      <TableCell className={classes.requirementsCell}>
                        <div className={classes.flexWrapper}>
                          <div className={classes.requirementsWrapper}>
                            {deal.requirements}
                          </div>
                          {(deal.requirements && deal.requirements.length > 130) ? (
                            <icons.MoreHoriz
                              onClick={event => this.handlePopoverOpen(event, deal.requirements)}
                              className={classes.popoverIcon}
                            />
                          ) : null}
                        </div>
                      </TableCell>
                      : null}
                    {columnsSettings.offers ? (() => {
                      const offers = deal.offers && deal.offers.length
                        ? deal.offers.map(offer => offer.formattedAddress).join('\n') : '';
                      return (
                        <TableCell title={offers || ''}>
                          {offers}
                        </TableCell>
                      );
                    })() : null}
                    {columnsSettings.action ?
                      <TableCell>
                        {requestActions[deal.action] || deal.action}
                      </TableCell>
                      : null}
                    {columnsSettings.result ?
                      <TableCell>
                        {requestResults[deal.result] || deal.result}
                      </TableCell>
                      : null}
                    {columnsSettings.assignee ?
                      <TableCell title={deal.assignees.map(assignee => assignee.name).join('\n')}>
                        {deal.assignees && deal.assignees.length > 0 ? deal.assignees[0].name : null}
                        {deal.assignees && deal.assignees.length > 1 ? (
                          <icons.MoreHoriz
                            onClick={event => this.handlePopoverOpen(event, deal.assignees.map(assignee => assignee.name).join('\n'))}
                            className={classes.popoverIcon}
                          />
                        ) : null}
                        <Typography variant="caption">
                          {formatDateWithoutTime(deal.updatedAt)}
                        </Typography>
                      </TableCell>
                      : null}
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPage={perPage}
              component={'div'}
              rowsPerPageOptions={[perPage]}
              count={totalCount}
              page={query.offset ? Math.floor(query.offset / perPage) : 0}
              onChangePage={handleChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {deals.map((deal, i) => {
            const feedbackStatus = this.getColoredStatus(deal);
            return (<Paper
              key={i}
              elevation={0}
              onClick={() => history.push(`/requests/${deal.id}`)}
              square
            >
              <CardContent className={classes.tableRow + ' _mobile'}>
                {feedbackStatus}
                <Typography>
                  {formatDateWithoutTime(deal.date)} - № {deal.no}
                </Typography>
                <Typography variant="subtitle1">
                  {deal.contactName ? deal.contactName + (deal.contactPhone ? ' / ' + deal.contactPhone : '') : null}
                </Typography>
                <Typography variant="caption">
                  {offerTypeLabels[deal.type] || deal.type}
                </Typography>
              </CardContent>
              <Divider/>
            </Paper>);
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={query.offset ? Math.ceil(query.offset / 30) : 0}
            handleOnChangePage={handleChangePage}
          />
        </Hidden>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
      </React.Fragment>
    );
  }

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };
}

const styles = theme => ({
  table: {
    background: 'white',
  },
  tableRow: {
    display: 'table-row',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    color: '#fff',
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-y',
    '&._mobile': {
      height: 'unset',
      display: 'block',
      position: 'relative',
      paddingLeft: 24,
    },
    '&:hover': {
      color: '#000',
      backgroundColor: 'rgba(0,0,0,0.07)',
      backgroundImage: 'none',
    },
    '& th:last-child,td:last-child': {
      width: 150,
      paddingRight: 0,
    },
  },
  tableColumn: {
    color: 'inherit',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 56,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  requirementsCell: {
    whiteSpace: 'normal',
    minWidth: 200,
  },
  requirementsWrapper: {
    overflow: 'hidden',
    maxHeight: 48,
    lineClamp: 3,
    fontSize: '0.75rem',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  status: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 6,
    height: '100%',
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  malformedIconHard: {
    color: colors.red[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  mobilePagination: {
    marginRight: 16,
  },
  rightIcon: {
    marginRight: -12,
  },
});

export default connect(
  state => ({
    dealSourceLabels: mapLabels(state.root.classification.dealSources, 'value', 'label'),
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    dealListSettings: state.root.settings.requestList,
    activitiesLabels: mapLabels(state.root.classification.activities, 'id', 'name'),
    activitiesDetailsLabels: mapLabels(state.root.classification.activitiesDetails, 'id', 'name'),
    requestActions: mapLabels(state.root.classification.requestActions, 'value', 'label'),
    requestResults: mapLabels(state.root.classification.requestResults, 'value', 'label'),
    requestFeedbackColors: mapLabels(state.root.classification.requestFeedback, 'value', 'color'),
  })
)(withStyles(styles)(RequestListItems));
