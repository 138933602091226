import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {addTeamMember, loadTeamMemberList, updateTeamMember} from '../../actions';
import {mapLabels} from '../../utils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {TextField} from 'redux-form-material-ui';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';
import axios from 'axios';
import * as colors from '@material-ui/core/colors';

class TelegramIntegration extends Component {
  state = {
    teamId: null,
    channel: {
      id: null,
      name: null,
      message: null,
    },
    responseError: null,
  };

  componentDidMount() {
    if (this.props.selfUser && this.props.selfUser.id && this.props.teamId) {
      this.initialize(this.props.teamId);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selfUser && this.props.selfUser.id && newProps.teamId !== this.props.teamId) {
      this.initialize(newProps.teamId);
    }
  }

  initialize(teamId) {
    axios
      .create()
      .get('/api/teamTelegram?teamId=' + teamId)
      .then(res => {
        if (res && res.data) {
          this.setState({channel: res.data});
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.error)
          this.setState({responseError: error.response.data.error});
        else
          this.setState({responseError: 'Неизвестная ошибка сервера'});
      })
      .then(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  handleSubmit = () => {
    const {teamId} = this.props;
    const {channel} = this.state;

    this.setState({submitting: true, responseError: null});
    axios
      .create()
      .post('/api/teamTelegram', {
        teamId,
        channelId: channel.id,
      })
      .then(res => {
        if (res && res.data) {
          this.setState({channel: res.data});
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.error)
          this.setState({responseError: error.response.data.error});
        else
          this.setState({responseError: 'Неизвестная ошибка сервера'});
      })
      .then(() => {
        this.setState({
          submitting: false,
        });
      });
  };

  handleCancel = () => {
    window.location.href = '/';
  };

  render() {
    const {selfUser, classes} = this.props;
    const {submitting, channel, responseError} = this.state;

    if (!hasRole(selfUser.role, userRoles.ADMIN) && !hasRole(selfUser.role, userRoles.MANAGER)) {
      return (
        <Dialog open maxWidth="xs" fullWidth>
          <DialogTitle>
            Подключить IS_CRENOW_BOT к команде
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Typography className={classes.text}>
              Нет прав доступа для выполнения данного действия
            </Typography>
          </DialogContent>
        </Dialog>
      );
    }

    return (
      <React.Fragment>
        <Dialog open maxWidth="sm" fullWidth>
          <DialogTitle>
            Подключить IS_CRENOW_BOT к команде
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Typography className={classes.text}>
              1. Создайте <b>Частный канал</b> в Telegram для команды.{' '}
              <a
                href="https://journal.tinkoff.ru/channel-create-telegram/"
                rel="noopener noreferrer"
                target="_blank"
              >Открыть инструкцию</a>.
            </Typography>
            <Typography className={classes.text}>
              2. Добавьте бота {' '}
              <a
                href="https://t.me/IS_CRENOW_BOT"
                rel="noopener noreferrer"
                target="_blank"
              >@IS_CRENOW_BOT</a> администратором канала: <br/>
              2.1 Зайдите в канал команды, нажмите на три точки и выберите «Управление каналом». <br/>
              2.2 Перейдите в раздел «Администраторы». <br/>
              2.3 Нажмите «Добавить». <br/>
              2.4 В поиске найдите Бота по системному имени через ввод «is_crenow». <br/>
              2.5 Добавьте его в качестве администратора и укажите все разрешения.
            </Typography>
            <Typography className={classes.text}>
              3. После добавления Бот через сообщение в канале покажет <b>ID канала</b>, его нужно указать в поле ниже.
            </Typography>
            {
              responseError ? (
                <Typography className={classes.error}>{responseError}</Typography>
              ) : null
            }
            {
              channel && channel.message ? (
                <Typography className={classes.success}>{channel.message}</Typography>
              ) : null
            }
            <Divider/>
            <TextField
              name="channelId"
              label="Укажите ID канала"
              type="text"
              onChange={event => this.setState({channel: {id: event.target.value, name: null}})}
              value={channel.id || ''}
              fullWidth
              margin="normal"
            />
            {
              channel && channel.name ? (
                <TextField
                  label="Название канала"
                  type="text"
                  value={channel.name}
                  fullWidth
                  margin="normal"
                  disabled
                />
              ) : null
            }

          </DialogContent>
          <Divider/>
          <DialogActions>
            <Button
              disabled={submitting}
              onClick={this.handleCancel}
            >
              Закрыть
            </Button>
            <Button
              type="submit"
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

TelegramIntegration = connect(
  (state, ownProps) => ({
    selfUser: state.root.selfUser,
    memberList: state.root.team.screens[ownProps.location.key],
    members: state.root.team.members,
    selfUserTeams: state.root.selfUser.teams,
    userRoleLabels: mapLabels(state.root.classification.userRoles, 'value', 'label'),
  }),
  {
    addTeamMember,
    loadTeamMemberList,
    updateTeamMember,
  }
)(TelegramIntegration);

const styles = () => ({
  text: {
    margin: '1rem 0',
  },
  error: {
    margin: '1rem 0',
    color: colors.red[400],
    fontWeight: 500,
  },
  success: {
    margin: '1rem 0',
    color: colors.green[800],
    fontWeight: 500,
  },
});

export default withRouter(withStyles(styles)(TelegramIntegration));
