import * as axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {setTitle} from '../../actions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import * as colors from '@material-ui/core/colors';
import {copyToClipboard, copyToClipboardFeedback} from '../../utils';
import Tooltip from '@material-ui/core/Tooltip';

class RequestCallContact extends Component {
  state = {
    contact: {
      name: '',
      phone: '',
      tel: '',
    },
    error: null,
    phoneCopied: false,
  };

  componentDidMount() {
    this.props.setTitle('Позвонить контакту');
    this.getContact();
  }

  getContact = () => {
    const {match} = this.props;

    return axios.get('/api/v1/getRequestCallContact?id=' + match.params.requestId)
      .then(res => {
        if (res && res.data) {
          this.setState({contact: res.data});
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.error) {
          this.setState({error: error.response.data.error});
        }
      });
  };

  handleCancel = () => {
    const {match} = this.props;
    window.location.href = '/requests/' + match.params.requestId;
  };

  render() {
    const {contact, error, phoneCopied} = this.state;
    const {classes} = this.props;

    if (error) {
      return (
        <React.Fragment>
          <Dialog open maxWidth="sm" fullWidth>
            <DialogTitle>
              Ошибка
            </DialogTitle>
            <Divider/>
            <DialogContent>
              {
                error ? (
                  <Typography className={classes.error}>{error}</Typography>
                ) : null
              }
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Button
                onClick={this.handleCancel}
              >
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Dialog open maxWidth="sm" fullWidth>
          <DialogTitle>
            {contact.name} <span className={classes.separator}>/</span> {contact.phone}
          </DialogTitle>
          <Divider/>
          <DialogContent className={classes.btnWrapper}>
            <Button
              href={'tel:' + contact.tel}
              variant="contained"
              color="secondary"
              className={classes.actionBtn}
            >
              Позвонить
            </Button>
            <Tooltip placement="top" open={phoneCopied} title="Скопировано">
              <Button
                onClick={
                  e => {
                    copyToClipboard(contact.phone, e.target).then(() => {
                      copyToClipboardFeedback(
                        () => this.setState({phoneCopied: true}),
                        () => this.setState({phoneCopied: false})
                      );
                    });
                  }
                }
                variant="contained"
                color="primary"
                className={classes.actionBtn}
              >
                Копировать
              </Button>
            </Tooltip>
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Button onClick={this.handleCancel}>
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  actionBtn: {
    marginRight: '2rem',
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'middle',
    lineHeight: '2rem',
    width: 160,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      margin: '1rem auto',
      display: 'block',
    },
  },
  btnWrapper: {
    textAlign: 'center',
    margin: '2rem 0',
    padding: 0,
  },
  error: {
    margin: '1rem 0',
    color: colors.red[400],
    fontWeight: 500,
  },
  separator: {
    [theme.breakpoints.down('xs')]: {
      height: '1px',
      display: 'block',
      visibility: 'hidden',
    },
  },
});

export default connect(
  null,
  {
    setTitle,
  }
)(withStyles(styles)(RequestCallContact));
