const PREFIX_JSON_ENCODED = 'json__';

export const isSupportedBroadcastMessage = () => {
  return typeof window.BroadcastChannel === 'function';
};

export const onBroadcastMessage = (channel, callback) => {
  if (!isSupportedBroadcastMessage() || typeof callback !== 'function') {
    return;
  }

  const broadcast = new BroadcastChannel(channel);
  broadcast.onmessage = e => {
    if (e && e.data && typeof e.data === 'string') {
      let message = e.data;
      if (message.indexOf(PREFIX_JSON_ENCODED) === 0) {
        message = message.slice(PREFIX_JSON_ENCODED.length);
        try {
          message = JSON.parse(message);
        } catch (e) {
          message = '';
        }
      }
      callback(message);
    }
  };
};

export const pushBroadcastMessage = (channel, message) => {
  if (!isSupportedBroadcastMessage()) {
    return;
  }

  if (typeof message === 'object') {
    message = PREFIX_JSON_ENCODED + JSON.stringify(message);
  }

  const broadcast = new BroadcastChannel(channel);
  broadcast.postMessage(message);
};
