import React, {Component} from 'react';
import {connect} from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import {
  createAreaRangeFormatter,
  createPriceFormatter,
  mapLabels,
  formatDate,
} from '../../utils';
import ListItemWithMenu from '../../components/ListItemWithMenu';
import AccessComponent from '../../components/AccessComponent';

class RequestDetails extends Component {
  render() {
    const {
      data,
      onActionChanged,
      onResultChanged,
      dealSourceLabels,
      offerTypeLabels,
      activitiesLabels,
      activitiesDetailsLabels,
      requestActions,
      requestResults,
    } = this.props;

    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          <AccessComponent manager>
            {data.reactionTimeFormatted ? (
              <ListItem dense>
                <ListItemText
                  primary={data.reactionTimeFormatted}
                  secondary={'Время реакции' + (data.reactionMember ? '; брокер - ' + data.reactionMember.name : '')}
                />
              </ListItem>
            ) : null}
          </AccessComponent>
          {data.type ? (
            <ListItem dense>
              <ListItemText primary={(offerTypeLabels[data.type] || data.type)} secondary="Вид сделки"/>
            </ListItem>
          ) : null}
          <ListItemWithMenu
            title="Действия"
            values={requestActions}
            selectedValue={data.action}
            menuValueName={'value'}
            menuLabelName={'label'}
            onChange={onActionChanged}
          />
          <ListItemWithMenu
            title="Результат"
            values={requestResults}
            selectedValue={data.result}
            menuValueName={'value'}
            menuLabelName={'label'}
            onChange={onResultChanged}
          />
          {data.source ? (
            <ListItem dense>
              <ListItemText primary={dealSourceLabels[data.source] || data.source} secondary="Источник"/>
            </ListItem>
          ) : null}
          {data.activity ? (
            <ListItem dense>
              <ListItemText primary={activitiesLabels[data.activity] || data.activity} secondary="Сфера деятельности"/>
            </ListItem>
          ) : null}
          {data.activityDetails ? (
            <ListItem dense>
              <ListItemText
                primary={activitiesDetailsLabels[data.activityDetails] || data.activityDetails}
                secondary="Детали деятельности"
              />
            </ListItem>
          ) : null}
          {data.areaFrom ? (
            <ListItem dense>
              <ListItemText primary={data.areaFrom + ' м²'} secondary="Площадь от"/>
            </ListItem>
          ) : null}
          {data.areaTo ? (
            <ListItem dense>
              <ListItemText primary={data.areaTo + ' м²'} secondary="Площадь до"/>
            </ListItem>
          ) : null}
          {data.priceFrom ? (
            <ListItem dense>
              <ListItemText primary={data.priceFrom + ' ₽'} secondary="Бюджет от"/>
            </ListItem>
          ) : null}
          {data.priceTo ? (
            <ListItem dense>
              <ListItemText primary={data.priceTo + ' ₽'} secondary="Бюджет до"/>
            </ListItem>
          ) : null}
          <ListItem dense>
            <ListItemText primary={formatDate(data.createdAt)} secondary="Создана"/>
          </ListItem>
          {data.updatedAt !== data.createdAt ? (
            <ListItem dense>
              <ListItemText primary={formatDate(data.updatedAt)} secondary="Изменена"/>
            </ListItem>
          ) : null}
        </List>
      </div>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
  menuPaper: {
    minWidth: 288,
  },
});

export default connect(
  ({root}) => ({
    dealSourceLabels: mapLabels(root.classification.dealSources, 'value', 'label'),
    dealStatusColors: mapLabels(root.classification.dealStatuses, 'value', 'color'),
    dealStatusLabels: mapLabels(root.classification.dealStatuses, 'value', 'label'),
    dealStatuses: root.classification.dealStatuses,
    dealClosesReason: root.classification.dealClosesReason,
    classificationDeal: root.classification.classificationDeal,
    formatPrice: createPriceFormatter(root.classification.priceTypes, root.classification.currencyCodes),
    formatAreaRange: createAreaRangeFormatter(root.classification.areaUnits),
    propertyPurposeLabels: mapLabels(root.classification.propertyPurposes, 'value', 'label'),
    offerTypeLabels: mapLabels(root.classification.offerTypes, 'value', 'label'),
    activitiesLabels: mapLabels(root.classification.activities, 'id', 'name'),
    activitiesDetailsLabels: mapLabels(root.classification.activitiesDetails, 'id', 'name'),
    requestActions: root.classification.requestActions,
    requestResults: root.classification.requestResults,
  })
)(withStyles(styles)(RequestDetails));
