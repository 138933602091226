import React, {Component} from 'react';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import {
  formatAddressListItem,
  formatAddressListItemTwoLines,
  formatDateWithoutTime,
  shortAddress,
  mapLabels,
  getPriceForM,
  getPriceForObj,
  createAreaFormatter,
  createListPriceFormatter,
} from '../../utils';
import {withStyles} from '@material-ui/core/styles';
import AccessComponent from '../../components/AccessComponent';
import TablePaginationActions from '../../components/TablePaginationActions';
import {downloadCsv} from '../../utils/csvFunc';
import {getOfferAlertIcon} from '../../utils/alerts';
import TableLink from '../../components/TableLink';

class OfferListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openExportMenu: null,
    loading: false,
  };

  render() {
    const {
      classes,
      query,
      offerList,
      selectedItems,
      propertyTypeLabels,
      action,
      offers,
      offerTypeLabels,
      offerStatusLabels,
      history,
      favList,
      handleOnChangePage,
      formatArea,
      formatPrice,
      handleRequestSort,
      offerListSettings,
      districtList,
      _perPage,
      actionRight,
      actionReset,
      actionResetMobile,
      showExportSettingsList,
    } = this.props;

    const {order} = query;
    const {anchorEl, popoverContent, openExportMenu, loading} = this.state;
    const columnsSettings = offerListSettings.columns;

    return (
      <React.Fragment>
        {
          showExportSettingsList ? (
            <p>123</p>
          ) :
            (
              <React.Fragment>
                <Hidden xsDown>
                  {actionReset ?
                    actionReset
                    : null}
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {actionRight ?
                          <TableCell />
                          : null}
                        <TableCell>
                          <TableSortLabel
                            active={order && order.column === 'address'}
                            direction={
                              order && order.direction
                                ? order.direction.toLowerCase()
                                : 'asc'
                            }
                            onClick={() => handleRequestSort('address')}
                            classes={{root: classes.sortableCell}}>
                            Адрес
                          </TableSortLabel>
                        </TableCell>
                        {columnsSettings.district ? (
                          <TableCell>Район</TableCell>
                        ) : null}
                        {columnsSettings.propertyType ? (
                          <TableCell>Категория</TableCell>
                        ) : null}
                        {columnsSettings.offerType ? (
                          <TableCell>Вид сделки</TableCell>
                        ) : null}
                        {columnsSettings.area ? (
                          <TableCell>
                            <TableSortLabel
                              active={order && order.column === 'area'}
                              direction={
                                order && order.direction
                                  ? order.direction.toLowerCase()
                                  : 'asc'
                              }
                              onClick={() => handleRequestSort('area')}
                              classes={{root: classes.sortableCell}}
                            >
                              Площадь
                            </TableSortLabel>
                          </TableCell>
                        ) : null}
                        {columnsSettings.priceForObj ? (
                          <TableCell>
                            <TableSortLabel
                              active={order && order.column === 'price'}
                              direction={
                                order && order.direction
                                  ? order.direction.toLowerCase()
                                  : 'asc'
                              }
                              onClick={() => handleRequestSort('price')}
                              classes={{root: classes.sortableCell}}
                            >
                              Цена за объект
                            </TableSortLabel>
                          </TableCell>
                        ) : null}
                        {columnsSettings.priceForM ? (
                          <TableCell>Цена за м2</TableCell>
                        ) : null}
                        {columnsSettings.owner ? (
                          <TableCell>Собственник</TableCell>
                        ) : null}
                        {columnsSettings.dates ? (
                          <TableCell>
                            <TableSortLabel
                              active={order && order.column === 'createdAt'}
                              direction={
                                order && order.direction
                                  ? order.direction.toLowerCase()
                                  : 'asc'
                              }
                              onClick={() => handleRequestSort('createdAt')}
                              classes={{root: classes.sortableCell}}
                              hideSortIcon={true}
                            >
                              Создан
                            </TableSortLabel>
                            &nbsp;/
                            <TableSortLabel
                              active={order && order.column === 'updatedAt'}
                              direction={
                                order && order.direction
                                  ? order.direction.toLowerCase()
                                  : 'asc'
                              }
                              onClick={() => handleRequestSort('updatedAt')}
                              classes={{root: classes.sortableCell}}
                            >
                              &nbsp;Изменен
                            </TableSortLabel>
                          </TableCell>
                        ) : null}
                        {columnsSettings.assignees ? (
                          <TableCell>Ответственный</TableCell>
                        ) : null}
                        {columnsSettings.status ? (
                          <TableCell>Статус</TableCell>
                        ) : null}
                        {!actionRight ?
                          <TableCell />
                          : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {offerList.items.map(id => {
                        const offer = offers[id];
                        const selected =
                          selectedItems && selectedItems.indexOf(offer.id) >= 0;
                        return (
                          <TableLink
                            className={classes.tableRow}
                            key={id}
                            href={`/offers/${id}`}
                            hover
                            selected={selected}
                            onClick={e => {
                              e.preventDefault();
                              history.push(`/offers/${offer.id}`);
                            }}
                          >
                            {actionRight ? (
                              <TableCell style={{padding: 0, paddingLeft: 5, width: 30}}>
                                {action ?
                                  <div >
                                    {action(offer, selected, 'offerId', favList)}
                                  </div>
                                  : null}
                              </TableCell>
                            ) : null}
                            <TableCell
                              className={classes.addressColumn}
                              title={formatAddressListItem(offer.property.address)}
                            >
                              {getOfferAlertIcon(offer)}
                              {
                                columnsSettings.shortAddress
                                  ? shortAddress(offer.property.address)
                                  : formatAddressListItemTwoLines(offer.property.address)
                              }
                            </TableCell>
                            {columnsSettings.district ? (
                              <TableCell>
                                {districtList[offer.property.address.district]}
                              </TableCell>
                            ) : null}
                            {columnsSettings.propertyType ? (
                              <TableCell>
                                {propertyTypeLabels[offer.property.type.value] ||
                                offer.property.type.value}
                              </TableCell>
                            ) : null}
                            {columnsSettings.offerType ? (
                              <TableCell>
                                {offerTypeLabels[offer.type] || offer.type}
                              </TableCell>
                            ) : null}
                            {columnsSettings.area ? (
                              <TableCell>{formatArea(offer.property.area)}</TableCell>
                            ) : null}
                            {columnsSettings.priceForObj ? (
                              <TableCell>
                                {formatPrice(getPriceForObj(offer))}
                              </TableCell>
                            ) : null}
                            {columnsSettings.priceForM ? (
                              <TableCell>{formatPrice(getPriceForM(offer))}</TableCell>
                            ) : null}
                            {columnsSettings.owner ? (
                              offer.property.owner.name ?
                                <TableCell title={`${offer.property.owner.name}\n${offer.property.owner.phone}`}>
                                  {offer.property.owner.name}
                                  {'\n'}
                                  {offer.property.owner.phone}
                                </TableCell>
                                : <TableCell/>
                            ) : null}
                            {columnsSettings.dates ? (
                              <TableCell>
                                {formatDateWithoutTime(offer.createdAt)}&nbsp;/{' '}
                                {formatDateWithoutTime(offer.updatedAt)}
                              </TableCell>
                            ) : null}
                            {columnsSettings.assignees ? (
                              <TableCell
                                title={offer.assignees
                                  .map(assignee => assignee.name)
                                  .join('\n')}
                              >
                                <div className={classes.flexWrapper}>
                                  <div>
                                    {offer.assignees && offer.assignees.length > 0
                                      ? offer.assignees[0].name
                                      : null}
                                  </div>
                                  {offer.assignees && offer.assignees.length > 1 ? (
                                    <icons.MoreHoriz
                                      onClick={event =>
                                        this.handlePopoverOpen(
                                          event,
                                          offer.assignees
                                            .map(assignee => assignee.name)
                                            .join('\n')
                                        )
                                      }
                                      className={classes.popoverIcon}
                                    />
                                  ) : null}
                                </div>
                              </TableCell>
                            ) : null}
                            {columnsSettings.status ? (
                              <TableCell>{offerStatusLabels[offer.status]}</TableCell>
                            ) : null}
                            {!actionRight ? (
                              <TableCell style={{paddingRight: 24}}>
                                {action ?
                                  <div className={classes.action}>
                                    {action(offer, selected, 'offerId', favList)}
                                  </div>
                                  : null}
                              </TableCell>
                            ) : null}
                          </TableLink>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <div className={classes.pagination}>
                    <AccessComponent manager>
                      <Button
                        size="small"
                        className={classes.button}
                        onClick={e =>
                          this.setState({openExportMenu: e.currentTarget})
                        }
                        disabled={loading}
                      >
                        <icons.ArrowDownward className={classes.leftIcon} />
                        Выгрузить
                      </Button>
                    </AccessComponent>
                    <TablePagination
                      rowsPerPage={_perPage}
                      component={'div'}
                      rowsPerPageOptions={[_perPage]}
                      count={offerList.totalCount}
                      page={offerList.page}
                      onChangePage={handleOnChangePage}
                      labelDisplayedRows={({from, to, count}) =>
                        `${from}-${to} из ${count}`
                      }
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </Hidden>
                <Hidden smUp>
                  {actionResetMobile ?
                    <Paper
                      className={classes.card}
                      elevation={0}
                      square
                    >
                      <CardContent style={{position: 'relative'}}>
                        <Typography>
                          Сбросить выбранные
                        </Typography>
                        {actionResetMobile}
                      </CardContent>
                      <Divider />
                    </Paper>
                    : null}
                  {offerList.items.map(id => {
                    const offer = offers[id];
                    const selected =
                      selectedItems && selectedItems.indexOf(offer.id) >= 0;
                    return (
                      <Paper
                        key={id}
                        className={classes.card}
                        elevation={0}
                        onClick={() => history.push(`/offers/${offer.id}`)}
                        square
                      >
                        <CardContent style={{position: 'relative'}}>
                          <Typography>
                            {propertyTypeLabels[offer.property.type.value] ||
                            offer.property.type.value}
                          </Typography>
                          <Typography variant="subtitle1">
                            {offerTypeLabels[offer.type] || offer.type},{' '}
                            {formatPrice(offer.price)}
                          </Typography>
                          <Typography variant="caption">
                            {formatAddressListItem(offer.property.address)}
                          </Typography>
                          {action ? (
                            <div className={actionRight ? classes.actionRightSM : classes.action}>
                              {action(offer, selected, 'offerId', favList)}
                            </div>
                          ) : null}
                        </CardContent>
                        <Divider />
                      </Paper>
                    );
                  })}
                  <MobilePaginationToolbar
                    totalCount={offerList.totalCount}
                    paddingBottom={actionResetMobile ? 90 : null}
                    page={offerList.page}
                    handleOnChangePage={(e, page) => handleOnChangePage(e, page)}
                  />
                </Hidden>
              </React.Fragment>
            )
        }
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
        {openExportMenu ? (
          <Menu
            anchorEl={openExportMenu}
            open={true}
            onClose={() => this.setState({openExportMenu: null})}
          >
            <MenuItem value="xlsx" onClick={() => this.onDownloadCsv('xlsx')}>
              Выгрузить в XLSX
            </MenuItem>
            <MenuItem value="csv" onClick={() => this.onDownloadCsv('csv')}>
              Выгрузить в CSV
            </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }

  onDownloadCsv = async exportFormat => {
    const {offerList} = this.props;
    try {
      this.setState({openExportMenu: null, loading: true});
      await downloadCsv('offers', offerList.filter, exportFormat);
      this.setState({loading: false});
    } catch (err) {
      this.setState({openExportMenu: null, loading: false});
      alert('Произошла ошибка');
    }
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };
}

const styles = theme => ({
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.07)',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 56,
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  table: {
    background: 'white',
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
    paddingBottom: 40,
  },
  actionRightSM: {
    top: 10,
    right: 10,
    position: 'absolute',
  },
  addressColumn: {
    maxWidth: '280px',
    width: '280px',
    padding: '0px',
  },
});

export default connect(state => ({
  propertyTypeLabels: mapLabels(
    state.root.classification.propertyTypes,
    'value',
    'label'
  ),
  offerStatusLabels: mapLabels(
    state.root.classification.offerStatuses,
    'value',
    'label'
  ),
  offerTypeLabels: mapLabels(
    state.root.classification.offerTypes,
    'value',
    'label'
  ),
  districtList: state.root.district.districts,
  formatArea: createAreaFormatter(state.root.classification.areaUnits),
  formatPrice: createListPriceFormatter(
    state.root.classification.currencyCodes
  ),
  offers: state.root.offer.offers,
  selfUser: state.root.selfUser,
  offerListSettings: state.root.settings.offerList,
}))(withStyles(styles)(OfferListItems));
