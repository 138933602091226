import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {reducer as formReducer} from 'redux-form';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';
import storage from 'redux-persist/lib/storage';

import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloProvider} from 'react-apollo';

import './index.css';
import App from './App';
import rootReducer from './reducers';

const config = {
  key: 'root',
  storage,
  whitelist:['hint'],
};

//

const httpLink = createHttpLink({
  uri: '/api/query/',
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    dataIdFromObject: obj => obj.id,
    // addTypename: false,
  }),
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    root: persistReducer(config,rootReducer),
    form: formReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(

  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={(<div>dddd</div>)} persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
// registerServiceWorker();
