import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as icons from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import * as colors from '@material-ui/core/colors';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class Contact extends Component {
  state = {
    error: null,
    loading: false,
    anchorEl: null,
  };

  render() {
    const {data} = this.props;

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Контакт
        </ListSubheader>
      }>
        {data ? (
          <ListItem
            component="a"
            href={'/requests/' + data.id + '/contact'}
            button
            dense
          >
            <Avatar style={{backgroundColor: colors.orange[300]}}>
              <icons.Contacts/>
            </Avatar>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body1">
                  {data.contactName}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography variant="caption" noWrap>
                    {data.contactPhone}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        ) : null}
      </List>
    );
  }
}

export default Contact;
